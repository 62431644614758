/* eslint-disable react/style-prop-object */
import PropTypes from "prop-types";
import { memo } from "react";
import "./amount.scss";
import { FormattedNumber } from "react-intl";
import { formatAmountForDisplay } from "app/utils/utils";
import classNames from "classnames";

/**
 * @see https://github.com/yahoo/react-intl/wiki/Components#formattednumber
 */
const Amount = ({ amount, currencyCode, prefix, suffix, shop = "", className = "" }) => {
	let amountToFormat = amount;

	if (isNaN(amountToFormat)) {
		return false;
	}

	const amountClassName = classNames("amount", className);

	// On veut que le libellé "Prix indisponible" ait le meme style que amount__prefix
	return (
		<div className={amountClassName} data-testid="amount">
			<>
				{prefix && <span className="amount__prefix">{prefix}</span>}
				<FormattedNumber
					value={amountToFormat}
					style="currency"
					currency={currencyCode}
					currencyDisplay="symbol"
					useGrouping={true}
					minimumFractionDigits={0}
					maximumFractionDigits={2}
				>
					{money => (
						<span className="amount__value">
							{formatAmountForDisplay(money, currencyCode, shop)}
						</span>
					)}
				</FormattedNumber>
				{suffix && <span className="amount__suffix">{suffix}</span>}
			</>
		</div>
	);
};

Amount.propTypes = {
	shop: PropTypes.string,
	amount: PropTypes.number,
	currencyCode: PropTypes.string,
	suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	className: PropTypes.string,
};

export default memo(Amount);
