import { memo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Paragraphs from "app/pages/.shared/Paragraphs";
import CurrentMerchCollection from "app/pages/.shared/CurrentMerchCollection/CurrentMerchCollection";
import "./MerchCarouselBlock.scss";
import { messagePropType } from "app/utils/propTypes";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";

const MerchCarouselBlock = ({
	title,
	introduction,
	merchandisingTitle,
	merchandisings = [],
	destinations = [],
	customItem = null,
}) => {
	const merchCarouselBlock = classNames({
		"merch-carousel-block__merchandisings": true,
		"merch-carousel-block__merchandisings--custom": customItem,
	});

	return (
		<div className="merch-carousel-block">
			{title && (
				<>
					<Typography
						variant={TYPOGRAPHY_VARIANTS.XL3}
						variantMD={TYPOGRAPHY_VARIANTS.XL4}
						isBold
						className="merch-carousel-block__title"
						component="h2"
					>
						<Paragraphs paragraphs={title} enableHTMLFormatting />
					</Typography>
					{introduction && (
						<div className="merch-carousel-block__introduction">
							<Paragraphs paragraphs={introduction} enableHTMLFormatting />
						</div>
					)}
				</>
			)}
			{merchandisingTitle && (
				<Typography
					variant={TYPOGRAPHY_VARIANTS.XL2}
					isBold
					className="merch-carousel-block__merch-title"
					component="h3"
				>
					<Paragraphs paragraphs={merchandisingTitle} enableHTMLFormatting />
				</Typography>
			)}

			<div className={merchCarouselBlock}>
				<CurrentMerchCollection
					merchandisingList={merchandisings}
					destinations={destinations}
				/>
				{customItem}
			</div>
		</div>
	);
};

MerchCarouselBlock.propTypes = {
	title: messagePropType,
	introduction: messagePropType,
	destinations: PropTypes.array,
	merchandisings: PropTypes.array,
	merchandisingTitle: messagePropType,
	customItem: PropTypes.element,
};

export default memo(MerchCarouselBlock);
