import { memo, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import BackgroundProgressiveImg from "app/pages/.shared/BackgroundProgressiveImg";
import ProgressiveImg from "app/pages/.shared/ProgressiveImg";
import { resolutionPropType } from "app/utils/propTypes";
import { stringify } from "qs";
import { useNavigate } from "react-router-dom";
import "./CurrentMerchCollectionItem.scss";
import IconRight from "app/pages/.shared/static/icons/IconRight";
import { htmlSanitize } from "app/utils/sanitize";
import DecreasingPriceStamp from "app/pages/.shared/DecreasingPriceStamp/DecreasingPriceStamp";
import RelativeLink from "app/pages/.shared/RelativeLink";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";
import { buildHotelOnlySearchParamsForDestination } from "app/utils/smartDPUtils";
import AppGlobalsContext from "app/AppGlobalsContext";

const IMG_SIZES_MOBILE = {
	small: { width: 265, height: 250 },
	medium: { width: 265, height: 250 },
};

const IMG_SIZES_DESKTOP = {
	large: { width: 420, height: 400 },
	xlarge: { width: 420, height: 400 },
};

const CLOUDINARY_OPTIONS = ["c_fill"];

const CurrentMerchCollectionItem = ({
	resolution,
	picture = {},
	lowestPrice,
	label,
	merchandisingTitle,
	merchandisingSubtitle,
	introduction,
	merchandisingCode,
	enableRedirectionToHotelOnlyListingPage,
	destinationFilterValues = [],
	destinations = [],
	position,
	indexCarousel,
	onClick = () => {},
	merchandisingStamp = {},
}) => {
	const { shop } = useContext(AppGlobalsContext);
	const navigate = useNavigate();

	let merchRedirectUrlData = {
		pathname: "/merch",
		search: `?${stringify({ code: merchandisingCode })}`,
	};

	const shouldNavigateToListing =
		enableRedirectionToHotelOnlyListingPage && destinationFilterValues?.length > 0;

	if (shouldNavigateToListing) {
		const destinationResort = destinations.find(
			destination => destination.label === destinationFilterValues[0]
		);

		if (destinationResort) {
			merchRedirectUrlData = {
				pathname: "/hotelonly/listing",
				search: `?${buildHotelOnlySearchParamsForDestination(destinationResort?.code)}`,
			};
		}
	}

	// handle click on image
	const navigateToMerch = useCallback(() => {
		if (shouldNavigateToListing) {
			window.open(
				`${window.location.origin}/${shop}${merchRedirectUrlData.pathname}${
					merchRedirectUrlData.search
				}`,
				"_blank"
			);
		} else {
			navigate(merchRedirectUrlData);
		}

		onClick({ merchandisingCode, position, indexCarousel });
	}, [
		merchandisingCode,
		position,
		indexCarousel,
		merchRedirectUrlData,
		shouldNavigateToListing,
		shop,
	]);

	const handleMerchClick = useCallback(() => {
		onClick({ merchandisingCode, position, indexCarousel });
	}, [merchandisingCode, position, indexCarousel]);

	return (
		<div
			className="current-merch-collection-item"
			data-testid={`current-merch-item-${merchandisingCode}`}
		>
			<div onClick={navigateToMerch}>
				<div className="current-merch-collection-item__picture">
					{resolution === RESOLUTION.MEDIUM || resolution === RESOLUTION.SMALL ? (
						<BackgroundProgressiveImg
							sizes={IMG_SIZES_MOBILE}
							cloudinaryOptions={CLOUDINARY_OPTIONS}
							src={picture.url}
							thumbnail={picture.thumbnail}
						/>
					) : (
						<ProgressiveImg
							className="progressive-img"
							sizes={IMG_SIZES_DESKTOP}
							src={picture.url}
							thumbnail={picture.thumbnail}
						/>
					)}
					{((merchandisingStamp?.showLowestPrice !== false && lowestPrice > 0) ||
						merchandisingStamp?.showLabel) && (
						<div className="current-merch-collection-item__stamp">
							<DecreasingPriceStamp
								showLowestPrice={merchandisingStamp?.showLowestPrice}
								showLabel={merchandisingStamp?.showLabel}
								label={merchandisingStamp?.label}
								showIcon={merchandisingStamp?.showIcon}
								icon={merchandisingStamp?.icon}
								lowestPrice={lowestPrice}
							/>
						</div>
					)}
				</div>
				<footer className="current-merch-collection-item__footer">
					<div className="current-merch-collection-item__footer-left">
						<Typography variant={TYPOGRAPHY_VARIANTS.LARGE} isBold>
							<div
								className="current-merch-collection-item__label"
								dangerouslySetInnerHTML={{
									__html: htmlSanitize(merchandisingTitle || label),
								}}
							/>
						</Typography>
						<div
							className="current-merch-collection-item__intro"
							dangerouslySetInnerHTML={{
								__html: htmlSanitize(merchandisingSubtitle || introduction),
							}}
						/>
					</div>

					<div className="current-merch-collection-item__footer-right">
						{/* keep using RelativeLink here to have <a> tag for seo purpose */}
						<RelativeLink
							variant="primary"
							to={merchRedirectUrlData}
							onClick={handleMerchClick}
							data-testid={`merch-cta-${merchandisingCode}`}
						>
							<IconRight width={20} height={20} />
						</RelativeLink>
					</div>
				</footer>
			</div>
		</div>
	);
};

CurrentMerchCollectionItem.propTypes = {
	label: PropTypes.string,
	picture: PropTypes.object,
	introduction: PropTypes.string,
	resolution: resolutionPropType,
	position: PropTypes.number,
	enableRedirectionToHotelOnlyListingPage: PropTypes.bool,
	destinationFilterValues: PropTypes.array,
	destinations: PropTypes.array,
	indexCarousel: PropTypes.number,
	merchandisingCode: PropTypes.string,
	merchandisingTitle: PropTypes.string,
	merchandisingSubtitle: PropTypes.string,
	lowestPrice: PropTypes.number,
	onClick: PropTypes.func,
	merchandisingStamp: PropTypes.object,
};

export default memo(CurrentMerchCollectionItem);
