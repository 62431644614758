import PropTypes from "prop-types";
import { memo } from "react";
import { FormattedMessage } from "react-intl";
import HelpDeskPhoneNumberContainer from "app/pages/.shared/HelpDeskPhoneNumber/HelpDeskPhoneNumberContainer";
import "./contact-experts.scss";

const ContactOurExperts = ({
	message = <FormattedMessage id="confirmation.contact.headline" />,
	showFAQLink = false,
	showPhonePicto = false,
	displayPhoneNumber = true,
}) => {
	return (
		<div className="contact-experts">
			<div className="contact-experts__message">{message}</div>
			<HelpDeskPhoneNumberContainer
				showFAQLink={showFAQLink}
				showPicto={showPhonePicto}
				displayPhoneNumber={displayPhoneNumber}
			/>
		</div>
	);
};

ContactOurExperts.propTypes = {
	showFAQLink: PropTypes.bool,
	showPhonePicto: PropTypes.bool,
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	displayPhoneNumber: PropTypes.bool,
};

export default memo(ContactOurExperts);
