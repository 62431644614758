import { memo } from "react";
import PropTypes from "prop-types";
import Paragraphs from "app/pages/.shared/Paragraphs";
import AmountContainer from "app/pages/.shared/AmountContainer";
import { FormattedMessage, useIntl } from "react-intl";
import "./DecreasingPriceStamp.scss";
import ExternalCurrentColorSVG from "app/pages/.shared/ExternalCurrentColorSVG/ExternalCurrentColorSVG";

const DecreasingPriceStamp = ({
	showLowestPrice = true,
	lowestPrice,
	showLabel,
	label,
	showIcon,
	icon = "/static/icons/decreasing-price-arrow.svg",
}) => {
	const intl = useIntl();

	// on veut afficher le prix d'appel par défaut
	let displayLowestPrice = true;
	if (showLowestPrice === false) {
		displayLowestPrice = false;
	}

	return (
		<div className="decreasing-price-stamp" data-testid="decreasing-price-stamp">
			{showIcon && (
				<div className="decreasing-price-stamp__icon">
					{icon && <ExternalCurrentColorSVG svgUrl={icon} />}
				</div>
			)}
			{showLabel && (
				<div className="decreasing-price-stamp__label">
					<Paragraphs
						paragraphs={
							label
								? label
								: intl.formatMessage({
										id: "general.decreasing.price.default.label",
								  })
						}
						enableHTMLFormatting
					/>
				</div>
			)}

			{showLabel && displayLowestPrice && (
				<span className="decreasing-price-stamp__separator">|</span>
			)}

			{displayLowestPrice && lowestPrice > 0 && (
				<div className="decreasing-price-stamp__price">
					<AmountContainer
						prefix={<FormattedMessage id="general.from.label" />}
						suffix={
							<FormattedMessage id="general.amount.per.person.suffix.label.short" />
						}
						amount={lowestPrice}
					/>
				</div>
			)}
		</div>
	);
};

DecreasingPriceStamp.propTypes = {
	showLowestPrice: PropTypes.bool,
	lowestPrice: PropTypes.number,
	showLabel: PropTypes.bool,
	label: PropTypes.string,
	showIcon: PropTypes.bool,
	icon: PropTypes.string,
};

export default memo(DecreasingPriceStamp);
