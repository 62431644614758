import { memo } from "react";
import PropTypes from "prop-types";
import "./ExternalCurrentColorSVG.scss";

/**
 * enable to have an external svg file for caching and coming for external source like sanity and be able to have the svg have the color of the text with currentcolor
 */
const ExternalCurrentColorSVG = ({ svgUrl }) => {
	return (
		<span
			className="external-current-color-svg"
			style={{
				maskImage: `url(${svgUrl}`,
				WebkitMaskImage: `url(${svgUrl}`,
			}}
		/>
	);
};

ExternalCurrentColorSVG.propTypes = {
	svgUrl: PropTypes.string,
};

export default memo(ExternalCurrentColorSVG);
